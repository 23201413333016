.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
td {
  white-space: inherit !important;
  height:auto !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.date-range-pickers {
  float: right;
  position: relative;
  z-index: 1000;
}

.date-range-pickers > span {
  color: #666;
}

.date-range-pickers .DayPickerInput > input {
  border: 1px solid #e6e6e6;
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 100px;
}

.date-range-pickers__to-label {
  margin-left: 10px;
}

.pl5 { padding-left: 5px; }
.pl10 { padding-left: 10px; }